import React from 'react'

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeItemIndex: 0,
      delay: 3000,
      lastChange: new Date().getTime(),
      paused: false,
    }
  }

  componentDidMount() {
    requestAnimationFrame(this.animationFrame)
  }

  animationFrame = () => {
    requestAnimationFrame(this.animationFrame)
    const { delay, lastChange, paused } = this.state
    const now = new Date().getTime()
    if (paused) {
      this.setState({ lastChange: now })
    } else if (now - lastChange >= delay) {
      this.goNext()
      this.setState({ lastChange: now })
    }
  }

  goNext = () => {
    const { activeItemIndex } = this.state
    const { items } = this.props
    let next = activeItemIndex + 1
    if (next > items.length - 1) next = 0
    this.setState({ activeItemIndex: next })
  }

  render() {
    const { activeItemIndex } = this.state
    const { items } = this.props

    return (
      <ul>
        {items.map((item, index) => (
          <li
            className={activeItemIndex === index ? 'active' : ''}
            key={item.id}
            onMouseEnter={() => {
              this.setState({ activeItemIndex: index, paused: true })
            }}
            onMouseLeave={() => {
              this.setState({ paused: false })
            }}
          >
            {item.url ? (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.label}
              </a>
            ) : (
              <span>{item.label}</span>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: item.hoverText.childMarkdownRemark.html,
              }}
            />
          </li>
        ))}
      </ul>
    )
  }
}

export default List

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import {
  GlobalStyle,
  Wrapper,
  Title,
  Subtitle,
  Content,
  ContentBlocks,
  ContentBlock,
  LatestWrapper,
  LatestHeading,
  LatestContent,
} from '../theme'

import List from '../components/List'

class RootIndex extends React.Component {
  render() {
    const content = get(this, 'props.data.allContentfulLayout.edges[0].node')

    return (
      <Wrapper>
        <GlobalStyle />
        <Helmet title={content.title} />
        <Title>{content.title}</Title>
        <Subtitle>{content.subtitle}</Subtitle>
        <Content>
          <LatestWrapper>
            <LatestHeading>Latest</LatestHeading>
            <LatestContent>
              {content.latestLink ? (
                <a
                  href={content.latestLink}
                  dangerouslySetInnerHTML={{
                    __html: content.latestText.childMarkdownRemark.html,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: content.latestText.childMarkdownRemark.html,
                  }}
                />
              )}
            </LatestContent>
          </LatestWrapper>
          <ContentBlocks>
            {content.items.map(item => (
              <ContentBlock key={item.id}>
                {item.items ? (
                  <List items={item.items} />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.content.childMarkdownRemark.html,
                    }}
                  />
                )}
              </ContentBlock>
            ))}
          </ContentBlocks>
        </Content>
      </Wrapper>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulLayout(limit: 1) {
      edges {
        node {
          title
          subtitle
          latestText {
            childMarkdownRemark {
              html
            }
          }
          latestLink
          items {
            ... on ContentfulList {
              id
              items {
                id
                label
                hoverText {
                  childMarkdownRemark {
                    html
                  }
                }
                url
              }
            }
            ... on ContentfulParagraph {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`

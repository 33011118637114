import styled, { createGlobalStyle } from 'styled-components'

import CHAPTER_EOT from '../fonts/Chapter-Regular.eot'
import CHAPTER_WOFF from '../fonts/Chapter-Regular.woff'
import CHAPTER_WOFF2 from '../fonts/Chapter-Regular.woff2'

import CLIPPING_PATH_1 from '../images/clipping-path-1.png'

const COLOUR__BACKGROUND = '#F7F1EA'
const COLOUR__TEXT__DEFAULT = '#0f4a81'
const COLOUR__TEXT__HIGHLIGHT = '#f47e41'

const FONT_FAMILY__SERIF = 'Chapter'
const FONT_FAMILY__SANS = "'Helvetica Neue', Helvetica, Arial, sans-serif"

const LINE_HEIGHT = 1.3

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-display: fallback;
    font-family: "${FONT_FAMILY__SERIF}";
    font-style: normal;
    font-weight: normal;
    src: url(${CHAPTER_WOFF}) format('woff'),
         url(${CHAPTER_WOFF2}) format('woff2');
  }

  html {
    background: ${COLOUR__BACKGROUND};
    color: ${COLOUR__TEXT__DEFAULT};
    font-family: ${FONT_FAMILY__SERIF};
    font-size: 1.6vw;
    margin: 0;

    @media screen and (max-width: 688px) {
      font-size: 11px;
    }
  }

  body {
    background: none;
    font-size: 1rem;
    line-height: ${LINE_HEIGHT}rem;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, p, ul {
    font-size: 1rem;
    font-weight: normal;
    line-height: ${LINE_HEIGHT}rem;
    margin: ${LINE_HEIGHT}rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-family: ${FONT_FAMILY__SANS};
    font-size: 2.6rem;
  }

  h2 {
    margin: ${LINE_HEIGHT * 2}rem auto ${LINE_HEIGHT * 5}rem;
    max-width: 18rem;
    text-align: center;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: -${LINE_HEIGHT}rem;
  }

  ul {
    font-family: ${FONT_FAMILY__SANS};
    list-style: none;
    margin: 0;
    padding: 0;
    pointer-events: none;
    position: relative;
  }

  li {
    margin: 0;
    padding: 0;
    pointer-events: auto;
    width: 40%;

    &.active {
      color: ${COLOUR__TEXT__HIGHLIGHT};

      > div { 
        display: flex;
      }
    }

    > div {
      align-items: center;
      bottom: ${LINE_HEIGHT * 8}rem;
      display: none;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      right: 0;
      text-align: center;
      top: ${LINE_HEIGHT * 3}rem;
      width: 50%;
    }
  }

a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: ${COLOUR__TEXT__HIGHLIGHT} !important;
  }
}

  a.anchor {
    display: none;
  }
`

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 32rem;
  padding: ${LINE_HEIGHT * 6}rem 1rem ${LINE_HEIGHT * 6}rem 1rem;
`

export const Title = styled.h1``

export const Subtitle = styled.h2``

export const Content = styled.div`
  position: relative;
`

export const ContentBlocks = styled.div``

export const LatestWrapper = styled.div`
  font-family: ${FONT_FAMILY__SANS};
  text-align: center;
  position: absolute;
  right: 5%;
  top: ${LINE_HEIGHT * 14}rem;
  transform: translate(-50%, -50%);
  width: 12rem;
`

export const LatestHeading = styled.h3`
  margin-bottom: 0;
`

export const LatestContent = styled.div``

export const ContentBlock = styled.div`
  clear: both;
  margin: ${LINE_HEIGHT}rem 0;
  text-align: left;

  &:nth-child(even) {
    text-align: right;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2) {
    > * {
      margin-top: ${LINE_HEIGHT * -4}rem;
    }
  }

  &:nth-child(3) {
    > * {
      margin-top: ${LINE_HEIGHT * -4}rem;
    }
  }

  &:nth-child(4) {
    > * {
      margin-top: ${LINE_HEIGHT * -4}rem;
    }
  }

  &:nth-child(5) {
    > * {
      margin-top: ${LINE_HEIGHT * -3}rem;
    }
  }

  &:nth-child(6) {
    > * {
      margin-top: ${LINE_HEIGHT * -8}rem;
    }
  }
`
